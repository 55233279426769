import 'select2';
import 'select2/dist/css/select2.css';
import "tippy.js/dist/tippy.css";
import "angular";
import "angular-ui-bootstrap";
import "angular-ckeditor";
import "angular-cookies";
import "angular-file-saver";
import "angular-moment";
import "angular-paginate-anything";
import "angular-sanitize";
import "angular-translate";
import "angular-translate-loader-partial";
import "angular-ui-ace";
import "angular-ui-mask";
import "angular-ui-notification";
import "ui-select";
import "bootstrap";
import "bootstrap-ui-datetime-picker";
import "file-saver";
import "jszip";
import moment from "moment";
import "ng-dialog";
import "ng-file-upload";
import "ngprogress/build/ngprogress.js";
import "signature_pad";
import "underscore";
import "angular-utils-pagination";
import "lodash";
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap-daterangepicker/daterangepicker.js';
import "leaflet/dist/leaflet-src";
import "leaflet/dist/leaflet.css";
import "leaflet-control-geocoder/dist/Control.Geocoder";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-draw/dist/leaflet.draw"
import "leaflet-draw/dist/leaflet.draw.css"

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
import "./backend.provider";
import "./applicationservice.controller";
import "./main.controller";
import "./ngprogress.factory";
import "./siteconfiguration.provider";
import "./helpers.service";
import './directives.directive';
import './single.application.controller';
import "./fileprocesslist.controller";
import "./documentlist.controller";
import {app} from "./app";

window.app = app;
window.moment = moment;
import "./user.applications.controller";
import "./servicelist.controller";
import "./profile.controller";
import "./partb.controller";
import "./inspector.controller";
import "./manage.businessentity.controller";
import "./business-entity-list.controller";
import "./applicationservice.businessentity.controller";
