import {Utils as DirectFormioUtils} from 'formiojs-latest';
import jsonLogic from 'json-logic-extension';
import * as _ from 'lodash';
import jwtDecode from 'jwt-decode';
import vNotify from "./notify.service";

app.controller('ApplicationServiceController', ['$scope', 'Backend', '$q', '$timeout', 'Notification', '$location', '$http',
    'Helpers', 'FormioScope', '$element', '$translate',
    '$window', '$translatePartialLoader', 'cfg', 'uiDatetimePickerConfig', '$rootScope', '$sce',
    function ($scope, Backend, $q, $timeout, Notification, $location, $http,
              Helpers, FormioScope, $element, $translate,
              $window, $translatePartialLoader, cfg, uiDatetimePickerConfig, $rootScope, $sce) {

        /**
         * This is too complex. What is form data, what is file_data. Too complex.
         * In some places, the file_data should be renamed to response (as what it is - a response from server)
         * @type {{data: {}}}
         */
        /* We keep this rather unpleasent structure to go along with the backend i.e: "role" is what "A5" becomes after submission.
        Still, we want one way to refer to forms, so we want to use A5 for example.
        Changing "role" to "A5" for started process is problematic due to backwards compatibility.components.
        So please leave this alone unless you are totally sure you know what you are doing. */
        var TYPE_MAP = {
            "role": "A5", "DOCUMENT": "A6", "PAYMENT": "PAYMENT", "REQUIREMENTREVIEW": "REQUIREMENTREVIEW",
            "ALERT": "ALERT", "ACTIVITYCONDITION": "ACTIVITYCONDITION", "GUIDE": "GUIDE",
            "applicantPayment": "PAYMENT", "SENDPAGE": "SENDPAGE"
        };

        var form = {
            'data': {}, "A5": null, "A6": null,
            "PAYMENT": null, "REQUIREMENTREVIEW": null, "ACTIVITYCONDITION": null, "GUIDE": null, "SENDPAGE": null
        };
        var self = this;
        Object.keys(jsonLogic.customOperations).forEach(function (k) {
            DirectFormioUtils.jsonLogic.add_operation(k, jsonLogic.customOperations[k]);
        });
        this.businessEntity = {isAddVisible: false, list: [], selected: {}, error: false};
        this.newBusinessEntity = {business_entity_id: null, name: "", isNew: true};
        this.GLOBALS = GLOBALS;
        this.form = form;
        this.isUserEmailVerified = false;
        this.isUnverifiedEmailAllowed = false;
        this.submissionInProgress = false;
        this.form_info_list = [];
        this.latestTransaction = null;
        this.formioSelectItemsMapping = {};
        this.initiated = false;
        this.paymentInProgress = false;
        this.pages = [
            {
                name: 'page1', progress: 0, formIds: ['GUIDE'], get isVisible() {
                    return Boolean(self.form.GUIDE && self.form.GUIDE.length);
                }
            },
            {
                name: 'page2', progress: 0, formIds: ['A5'], get isVisible() {
                    return Boolean(self.form.A5 && self.form.A5.length);
                }
            },
            {
                name: 'page3', progress: 0, formIds: ['A6'], get isVisible() {
                    return Boolean(self.form.A6 && self.form.data.showdocumenttab);
                }
            },
            {
                name: 'page4', progress: 0, formIds: [], get isVisible() {
                    if (!self.form.data.totalCost) return false;
                    return !self.form.data.isInitialPaymentDisabled;
                }
            },
            {
                name: 'page5', progress: 0, formIds: ['SENDPAGE'], get isVisible() {
                    return Boolean(self.form.SENDPAGE && self.form.SENDPAGE.length)
                }
            }
        ];
        this.pages.forEach(function (page, i) {
            if (i === 0) {
                page.prev = null;
            } else {
                page.prev = self.pages[i - 1];
            }
            Object.defineProperty(page, 'displayedNum', {
                get: function () {
                    var prev = this.prev;
                    while (prev && !prev.isVisible) {
                        prev = prev.prev;
                    }
                    if (!prev) {
                        return 1;
                    }
                    return prev.displayedNum + 1;
                }
            });
        });
        $scope.$watch('app_service.pages', function () {
        }, true);
        this.file = {"data": {}};
        this.file_has_errors = false;
        $scope.file_data = this.file;
        this.page = 'loading';
        this.isLesothoLicenseSearchComplete = false;

        this.setInitialPage = function () {
            var pageSetupDone = false;
            if (!$location.$$hash || !this.pages.some(function (page) {
                if (page.isVisible && page.name === $location.$$hash) {
                    self.page = page;
                    pageSetupDone = true;
                    return true;
                }
            })) ;

            if (!pageSetupDone) {
                this.pages.some(function (page) {
                    if (page.isVisible) {
                        self.page = page;
                        return true;
                    }
                });
            }

            this.initiated = true;
        };

        this.onSetPage = function ($event) {
            var href = $event.currentTarget.getAttribute("href");
            self.pages.some(function (page) {
                if (page.name === href.substr(1)) {
                    self.page = page;
                    return true;
                }
            });
            window.scrollTo(0, 0);
            $scope.blockErrorsHeader = false;
        };

        this.visiblePages = function () {
            return this.pages.filter(function (page) {
                return page.isVisible;
            });
        }

        this.getNextVisiblePage = function (page) {
            var nextPage = null;
            var initialPageLocated = false;
            for (var i = 0; i < self.pages.length; i++) {
                if (self.pages[i] === page) {
                    initialPageLocated = true;
                    continue;
                }
                if (!initialPageLocated) {
                    continue;
                }
                if (self.pages[i].isVisible) {
                    nextPage = self.pages[i];
                    break;
                }
            }
            if (!nextPage) {
                nextPage = page;
            }

            return nextPage;
        }

        this.calculateProgressForPages = function (onInit) {
            var percentBase = onInit ? 100 : 90;
            //console.log(percentBase, onInit);
            self.pages.forEach(function (page) {
                if (!onInit && (page !== self.page)) return;
                var all_stats = {'all': 0, 'missing': 0}, stats;
                page.formIds.forEach(function (form_id) {
                    if (!self.form[form_id]) {
                        return;
                    }
                    angular.forEach(self.form[form_id], function (f) {
                        stats = Helpers.getFormPercantage(f, self.form.data);
                        all_stats.all += stats.all;
                        all_stats.missing += stats.missing;
                    })
                });
                if (all_stats.all) {
                    page.progress = percentBase - parseInt((all_stats.missing / all_stats.all) * 100, 10);
                }
            });
        };

        this.getLoginWithRedirect = function () {
            return "/site-register?redirectTo=" + encodeURIComponent(window.location.pathname + window.location.search + window.location.hash);
        }

        this.initService = function (service, backTo, options) {
            if (service && service.props && service.props.customTheme) {
                GLOBALS.setupTheme(service.props.customTheme);
                self.customTheme = service.props.customTheme;
            }
            //console.log(cfg, service);
            addEventListener("RELOAD_FORM", function (ev) {
                self.loadDefaultForms(self.service_id, self.is_draft);
                setTimeout(function () {
                    document.getElementById(ev.detail).click();
                }, 500);
            });
            addEventListener("REGISTER_USER", function (ev) {
                self.onRegistrationRedirect(ev);
            });
            addEventListener("PRINTOUT", this.printAnyForm.bind(this));
            addEventListener("PAYMENT", this.onSubmitPayment.bind(this))
            addEventListener("saveA5", function (ev) {
                self.onSubmitForms(ev, ['A5'], self.pages[1], self.pages[2]);
            });
            addEventListener("updateDocuments", function () {
                if (self.file_id) {
                    Backend.updateFile(self.file_id, 'A6', self.form.data, true);
                } else {
                    var url = Backend.backend_url + "process/" + self.process_id + '/role-forms/' + self.active_task.camundaName + '/A6';
                    fetch(url, {
                        method: 'PATCH',
                        body: JSON.stringify({data: self.form.data}),
                        headers: {
                            Authorization: 'Bearer ' + self.token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(function (err) {
                    });
                }
            });
            addEventListener("saveSENDPAGE", function (ev) {
                self.onStartProcess(ev);
            });
            addEventListener('A6', function (ev) {
                self.onSubmitForms(ev, ['A6'], self.pages[2], self.pages[3]);
            });

            self.options = options;
            var service_id = self.service_id = service.service_id;
            self.serviceName = service.name;
            document.title = self.serviceName;
            if (cfg.isUserEmailVerified) {
                self.isUserEmailVerified = cfg.isUserEmailVerified;
            }
            self.isUnverifiedEmailAllowed = cfg.isUnverifiedEmailAllowed;
            if (cfg.token) {
                self.token = cfg.token;
            }

            if (backTo) {
                if (!cfg.user) {
                    // if user is not defined, try to get it only in case we come from somewhere else
                    window.location.href = self.getLoginWithRedirect();
                    return;
                }
                self.url = backTo;
            } else {
                self.url = '/';
            }

            if (inIframe()) {
                self.is_draft = true;
                // we need to fetch jwt tokens from bpa
                if (window.parent && window.parent.postMessage) {

                    $($window).on("message", function (e) {
                            var data = e.originalEvent.data;
                            if (data.action == 'get_token_resp') {
                                self.token_jwt = data['token_jwt'];
                                Backend.setTokenJwt(self.token_jwt);
                                self.loadDefaultForms(service_id, self.is_draft);
                            }
                        }
                    );

                    window.parent.postMessage({'action': 'get_token'}, '*');
                }

                if (cfg.fakeEditToken) {
                    self.token_jwt = cfg.fakeEditToken;
                    Backend.setTokenJwt(self.token_jwt);
                    self.loadDefaultForms(service_id, self.is_draft);
                }

                self.page = self.pages[0];
            } else {
                self.is_draft = false;
            }

            var promises = [];
            if (self.is_draft) {
                /* load local storage file */
                self.file_id = "file-" + service_id;
                var data_string = localStorage.getItem(self.file_id);
                if (data_string) {
                    self.form.data = JSON.parse(data_string);
                    self.form.data['dateTimeNow'] = new Date().toISOString();
                }
                /* TODO: Remove the empty promise once it is established why it is needed to load this controller properly in preview.
                         Without this, the draft load is broken. */
                var promise = $q(function (resolve, reject) {
                    setTimeout(function () {
                        resolve(null);
                        self.setInitialPage();

                        setTimeout(function () {
                            if (window.parent) {

                                /* Edit form data */
                                $('div.bpa-edit-area').on("dblclick", function (event) {
                                    event.preventDefault();

                                    /* Either translation post or edit post*/
                                    var tag = $(event.target).attr('translate');
                                    if (tag === undefined || !tag.length) {
                                        var id = $(event.target).closest(".form-group");
                                        if (id.length) {
                                            var id_value = id.attr("id");

                                            if (id_value.substr(0, 11) == "form-group-") {
                                                id_value = id_value.substr(11);
                                            }
                                        } else {
                                            id_value = "";
                                        }

                                        var elem = $(this).closest(".bpa-edit-area");

                                        var data = {
                                            "action": "translation_edit",
                                            "data_type": "form",
                                            "data_id": id_value,
                                            "form_type": elem.attr("data-form-type"),
                                            "service_id": self.service_id,
                                            "language": CURRENT_LANGUAGE
                                        };

                                        console.log(data);
                                        if (window.parent) {
                                            window.parent.postMessage(data, "*")
                                        }
                                    }

                                    return false;
                                });

                                $(window).on("message onmessage", function (e) {
                                    var data = e.originalEvent.data;
                                    // TODO: implement more sophisticated refresh strategy
                                    if (data.action && data.action !== "edit" && data.action !== "translation_edit" &&
                                        data.action !== 'get_token_resp' && data.action !== 'get_token' && data.action !== 'resize' &&
                                        data.action !== 'get_all_translations' && data.action !== 'get_all_translations_resp') {
                                        window.location.reload();
                                    }
                                });
                            }
                        }, 2000);

                    }, 1000);
                });

                promises.push(promise);
            } else {
                var process_id = Helpers.detectProcessId('process_id');
                var file_id = Helpers.detectProcessId('file_id');

                if (file_id || process_id) {
                    promises.push(Backend.getTransactions(file_id ? {file_id: file_id} : {process_id: process_id}).then(function (response) {
                        if (!response || !response.data || !response.data.length) return;
                        self.latestTransaction = response.data[0];
                        self.latestTransaction.message = self.latestTransaction.provider_transaction_status;
                    }).catch(function () {
                    }));
                }

                if (process_id) {
                    /* load process */
                    promises.push(Backend.getProcess(process_id).then(function (file) {
                        self.file = file;
                        self.process_id = file.id;
                        self.calculateProgressForPages(true);
                    }));
                } else if (file_id) {
                    if (!self.token) {
                        this.saveFileIdToCookie(file_id);
                    }
                    /* load file */
                    promises.push(Backend.getFile(file_id).then(function (file) {
                        self.file = file;
                        self.file_id = file.file_id;
                        self.form.data = file.data;
                        self.form.data['dateTimeNow'] = new Date().toISOString();
                        self.form.data.is_submit_allowed = Boolean(self.options.submit_allowed);
                        self.setupDefaultPayment();
                        self.form.data['username'] = cfg.user;
                        $scope.username = cfg.user;
                        self.calculateProgressForPages(true);
                        self.onNotSubmittedFileLoaded();
                    }));
                } else {
                    /* create new file */
                    promises.push(self.createNewFile(service_id));
                }
            }

            /* all the file data is loaded */
            $q.all(promises).then(function () {
                //console.log("LOAD service" + service_id + ' draft: ' + self.is_draft, self.file);
                if (self.process_id) {
                    self.loadCamundaForms();
                } else {
                    //take default form
                    if (!self.is_draft || (self.is_draft && self.token_jwt)) {
                        self.loadDefaultForms(service_id, self.is_draft);
                        self.updateDataFromJWT();
                    }
                }
            });
            //TODO ensure this is called after file init
            uiDatetimePickerConfig.buttonBar.cancel.text = $translate.instant('cancel');
            uiDatetimePickerConfig.buttonBar.close.text = $translate.instant('close');
            uiDatetimePickerConfig.buttonBar.now.text = $translate.instant('now');
            uiDatetimePickerConfig.buttonBar.today.text = $translate.instant('today');
            uiDatetimePickerConfig.buttonBar.clear.text = $translate.instant('clear');
            uiDatetimePickerConfig.buttonBar.date.text = $translate.instant('date');
            uiDatetimePickerConfig.buttonBar.time.text = $translate.instant('time');
        };

        this.createNewFile = function (service_id) {
            return Backend.newFile(service_id).then(function (resp) {
                self.file = resp.data;
                self.file_id = resp.data.file_id;
                self.form.data = resp.data.data;
                self.form.data['dateTimeNow'] = new Date().toISOString();
                if (self.options) {
                    self.form.data.is_submit_allowed = Boolean(self.options.submit_allowed);
                }
                self.setupDefaultPayment();
                if (self.file.service_is_external && self.token) {
                    return Backend.deleteFile(self.file_id);
                }
                $location.search("file_id", self.file_id);
                var langSwitches = Array.from(document.querySelectorAll("[id^='lang-switch']"));
                if (langSwitches) {
                    langSwitches.forEach(function (el) {
                        if (el.href && !el.href.includes("?")) {
                            el.href += "?file_id=" + self.file_id;
                        }
                    });
                }
                if (!self.token) {
                    self.saveFileIdToCookie(self.file_id);
                }
                self.onNotSubmittedFileLoaded();
            });
        }

        this.setupDefaultPayment = function () {
            if (!self.form || !self.form.data) return;
            if (!self.form.data.paymentProviderSelected) {
                Object.keys(self.form.data).forEach(function (key) {
                    if (key.startsWith("paymentProviderSelected")) {
                        self.form.data.paymentProviderSelected = self.form.data[key];
                    }
                });
                if (!self.form.data.paymentProviderSelected) {
                    self.form.data.paymentProviderSelected = 'frontdesk';
                }
            }
        }

        this.onNotSubmittedFileLoaded = function () {
            if (GLOBALS.USE_BUSINESS_ENTITIES && !self.file.service_is_external) {
                self.initBusinessEntitySelection();
            }
        }

        this.setupCompanyNameFromBusinessEntity = function () {
            if (SERVICE_FORMS['A5'] && SERVICE_FORMS['A5'][0] && self.form.data) {
                var companyNameComp = Helpers.getFormioComponentByTag(SERVICE_FORMS['A5'][0], 'company-name');
                if (companyNameComp && self.businessEntity.selected.name) {
                    if (self.isCompanyNameSetupFromEntity || !self.form.data[companyNameComp.key]) {
                        self.form.data[companyNameComp.key] = self.businessEntity.selected.name;
                        self.isCompanyNameSetupFromEntity = true;
                    }
                }
            }
        }

        this.getBusinessEntities = function () {
            return Backend.getBusinessEntities().then(function (data) {
                if (data && data.count) {
                    self.businessEntity.list = data.results.filter(function (be) {
                        return be.has_create_access;
                    });
                }
                return self.businessEntity.list;
            }).catch(function () {
            });
        }

        this.toggleBusinessEntitySelection = function (show) {
            var modal = angular.element('#select-business-entity').modal();
            if (show) {
                modal.show();
                angular.element('#overlay').show();
            } else {
                modal.hide();
                angular.element('#overlay').hide();
                angular.element('body').removeClass("modal-open");
                self.setupCompanyNameFromBusinessEntity();
            }
        }

        this.changeEntity = function (be) {
            console.log(be);
            self.businessEntity.selected = be;
            console.log(self.businessEntity);
        }

        this.acceptEntity = function (be) {
            Backend.patchFile(self.file_id, {business_entity_id: self.businessEntity.selected.business_entity_id}).then(function (cd) {
                self.businessEntity.error = false;
                self.toggleBusinessEntitySelection();
            }, function (err) {
                self.businessEntity.error = true;
            });
        }

        this.getPreselectedBE = function () {
            return location.search.replace(/.+preselectedBE=(.+?)(&.+|$)/, '$1');
        }

        this.initBusinessEntitySelection = function () {
            self.getBusinessEntities().then(function () {
                if (!self.businessEntity.list || self.businessEntity.list.length == 0) return;
                self.businessEntity.list.some(function (entity) {
                    if (entity.business_entity_id === self.file.business_entity_id) {
                        self.businessEntity.selected = entity;
                        return true;
                    }
                });
                if (!self.businessEntity.selected.business_entity_id) {
                    var preslectedBE = self.getPreselectedBE();
                    if (preslectedBE) {
                        self.businessEntity.list.some(function (entity) {
                            if (entity.business_entity_id === preslectedBE) {
                                self.businessEntity.selected = entity;
                                return true;
                            }
                        });
                    }
                    self.toggleBusinessEntitySelection(true);
                }
            });

            jQuery(document).ready(function () {
                self.businessEntity.add = function () {
                    if (_.trim(self.businessEntity.selected.name)) {
                        Backend.addBusinessEntity({name: self.businessEntity.selected.name}).then(function (cb) {


                            self.businessEntity.selected = cb.data;
                            console.log("BE added =>", self.businessEntity.selected);
                            Backend.patchFile(self.file_id, {business_entity_id: self.businessEntity.selected.business_entity_id}).then(function (cb) {
                                self.businessEntity.error = false;
                                self.toggleBusinessEntitySelection();
                            }, function (err) {
                                self.businessEntity.error = true;
                                console.log("ERRRR")
                            });
                        });
                    }
                }
            });
        }

        this.saveFileIdToCookie = function (file_id) {
            var currentStartedFiles = document.cookie.match(/.+?startedFiles=(.+?)(;|$)/);
            if (!currentStartedFiles || !currentStartedFiles[1]) {
                currentStartedFiles = [];
            } else {
                currentStartedFiles = currentStartedFiles[1].split(',');
            }
            if (!currentStartedFiles.includes(file_id)) {
                currentStartedFiles.push(file_id);
            }
            document.cookie = 'startedFiles=' + currentStartedFiles.join(',') + ';path=/;max-age=' + 60 * 60 * 24 * 90 + ';';
        }

        this.loadCamundaForms = function () {
            // var role = {'type': 'applicant'};
            self.file.tasks.some(function (task) {
                if (task.status === 'filepending') {
                    self.active_task = task;
                    return true;
                }
            });

            Backend.getRoleProcessVariables(self.file, self.active_task.camundaName).then(function (file_data) {
                self.form.data = file_data.variables;
                self.form.data['username'] = cfg.user;
                self.setupDefaultPayment();
                if (self.active_task.assignee === "applicantPayment") {
                    self.form.data.isInitialPaymentDisabled = false;
                }
                $scope.username = cfg.user;
                self.form.url = file_data.form_url;
                self.form.submit_url = file_data.form_submit_url;
            });
            var data = Helpers.parseCamundaProperties(self.file.tasks);
            angular.forEach(data['role_form_properties'], function (form_prop) {
                if (['review', 'reviewwithtabs', 'conclusion'].indexOf(form_prop.formType) < 0) {
                    Backend.getFormioForm(form_prop.formId).then(function (form_data) {
                        var formType = TYPE_MAP[form_prop.formType];
                        if (!formType) {
                            formType = form_prop.formType;
                        }

                        if (self.form[formType] === null) {
                            /* change the url based on process_id */
                            self.form[formType] = Helpers.changeProcessUploadUrl(form_data.data, self.file.id, '$4');
                        } else {
                            /* all forms are in list */
                            /* change the url based on process_id */
                            var temp = Helpers.changeProcessUploadUrl(form_data.data, self.file.id, '$4');
                            angular.forEach(temp, function (f) {
                                if (!self.form[formType]) {
                                    self.form[formType] = [];
                                }
                                self.form[formType].push(f);
                            });
                        }
                        if (formType === "PAYMENT" && self.form[formType] && self.form[formType][0] && self.active_task.assignee === "applicantPayment") {
                            var comp = DirectFormioUtils.findComponents(self.form[formType][0].components, {
                                key: "isInitialPaymentDisabled"
                            });
                            if (comp && comp.length) {
                                delete comp[0].calculateValue;
                            }
                        }
                        self.setInitialPage();
                    });
                }
            });
        };


        this.loadDefaultForms = _.debounce(function (service_id, is_draft, isSecondRun) {
            var form_types = ['A5', 'A6', 'PAYMENT', 'REQUIREMENTREVIEW', 'ACTIVITYCONDITION', 'GUIDE', 'SENDPAGE', 'GUIDEMENU', 'GUIDEMENUPANEL'];

            var load_forms = [];
            if (!isSecondRun && SERVICE_FORMS.useSchemaFilter && SERVICE_FORMS.FORMS_TO_FILTER) {
                var promises = [];
                SERVICE_FORMS.FORMS_TO_FILTER.forEach(function (k) {
                    promises.push(Backend.getFilteredForm(SERVICE_FORMS[k][0].path, self.form.data).then(function (res) {
                        SERVICE_FORMS[k][0] = res.data;
                    }));
                    $q.all(promises).then(function () {
                        self.loadDefaultForms(service_id, is_draft, true);
                    });
                });
                return;
            }
            form_types.forEach(function (form_type_code) {
                if (SERVICE_FORMS && SERVICE_FORMS[form_type_code]) {
                    self.form[form_type_code] = [];
                    SERVICE_FORMS[form_type_code].forEach(function (form) {
                        if (form.components && form.components.length) {
                            self.form[form_type_code].push(form);
                        }
                    });

                    if (form_type_code === 'A5' || form_type_code === 'A6' || form_type_code === 'PAYMENT') {
                        self.form[form_type_code] = Helpers.changeUploadUrl(self.form[form_type_code], self.file_id, '$4');
                    }
                } else {
                    if (!(form_type_code in SERVICE_FORMS)) {
                        load_forms.push(form_type_code);
                    }
                }
            });

            var forms = [], f;
            if (load_forms.length) {
                f = Backend.getFormDefinition(service_id, load_forms.join(";"), is_draft).then(function (resp) {
                    if (!resp.data) {
                        return
                    }
                    form_types.forEach(function (arrayElem) {
                        self.form[arrayElem] = [];
                        var d = resp.data[arrayElem];
                        if (!d || !d.length) {
                            d = [];
                        }
                        d.forEach(function (form) {
                            if (form.components && form.components.length) {
                                self.form[arrayElem].push(form);
                            }
                        })
                    });

                    if (self.form.A5) {
                        self.form["A5"] = Helpers.changeUploadUrl(self.form.A5, self.file_id, '$4');
                    }
                    if (self.form.A6) {
                        self.form["A6"] = Helpers.changeUploadUrl(self.form.A6, self.file_id, '$4');
                    }
                });
                forms.push(f);
            }

            if (forms.length) {
                $q.all(forms).then(function () {
                    self.calculateProgressForPages(true);
                    if (inIframe()) {
                        jQuery(document).ready(function () {
                            $('.determinant label').popover({
                                content: 'Double click to configure the determinant',
                                trigger: 'hover',
                                placement: 'auto'
                            });
                            $('.registrations label').popover({
                                content: 'Double click to configure the registration',
                                trigger: 'hover',
                                placement: 'auto'
                            });
                        });
                    }
                })
            } else {
                if (inIframe()) {
                    jQuery(document).ready(function () {
                        $('.determinant label').popover({
                            content: 'Double click to configure the determinant',
                            trigger: 'hover',
                            placement: 'auto'
                        });
                        $('.registrations label').popover({
                            content: 'Double click to configure the registration',
                            trigger: 'hover',
                            placement: 'auto'
                        });
                    });
                }
            }
        }, 500);

        /**
         * Returns proper formio url (public or draft)
         * @param form_url
         */
        this.getFormioFormUrl = function (form_url, form_type) {
            if (self.is_draft) {
                form_url += '?draft=1';
            }

            self.form_info_list.push({"form_url": form_url, "form_type": form_type});

            return form_url;
        };

        this.registerFormUrl = function (form_url, form_type) {
            this.form_info_list.push({"form_url": form_url, "form_type": form_type});
        };

        this.formatServerError = function (source) {
            var message = "<p>" + $translate.instant('Server error') + "</p><ul>";
            if (source.data.details && source.data.details.length > 0) {
                for (var n = 0; n < source.data.details.length; n++) {
                    message += "<li>" + source.data.details[n].message + "</li>";
                }
            } else if (source.data && source.data.length > 0) {
                message += "<li>" + source.data + "</li>";
            }
            message += "</ul>";
            return message;
        }

        this.formUpdateFailed = function (response) {
            console.log("----- failed -----", response, "----- /failed -----")
            Notification.error({message: self.formatServerError(response), replaceMessage: true});
            self.submissionInProgress = false;
            self.form['can_complete'] = true;
            self.clicked = false;
        };

        this.nextPageFromDocuments = function (next_page) {
            if (next_page && next_page.name === 'page4') {
                if (self.pages[3].isVisible) {
                    next_page.progress = 100;
                    next_page = self.pages[3];
                } else {
                    next_page = self.pages[4];
                }
            }
            if (next_page && next_page.name === 'page3') {
                if (!self.form.data.showdocumenttab) {
                    next_page.progress = 100;
                    return self.nextPageFromDocuments(self.pages[3]);
                }
            }

            return next_page;
        };

        this.tempSave = function ($event, form_types, current_page, next_page) {
            $event.preventDefault();
            if (self.submissionInProgress) return;
            self.submissionInProgress = true;
            self.clicked = true;

            var promises = [], stats;
            if (!self.is_draft) {
                var all_stats = {'all': 0, 'missing': 0};
                angular.forEach(form_types, function (form_type) {
                    var stats = Helpers.getFormPercantage(self.form[form_type], self.form.data);
                    all_stats.all += stats.all;
                    all_stats.missing += stats.missing;
                });

                if (all_stats.all && all_stats.missing) {
                    current_page.progress = parseInt((all_stats.all / all_stats.missing) * 100, 10);
                } else {
                    current_page.progress = 100;
                }

                var selected_form_types = [];
                angular.forEach(form_types, function (form_type) {
                    if (self.file_id) {
                        promises.push(Backend.updateFile(self.file_id, form_type, self.form.data, true).then(() => vNotify.success({text: GLOBALS.$translate("Saved successfully!")})));
                    } else {
                        selected_form_types.push(form_type);
                    }
                });

                if (selected_form_types.length) {
                    promises.push(Backend.updateProcess(self.process_id, self.active_task.camundaName, selected_form_types.join(";"), self.form.data));
                }


                $q.all(promises).then(function () {
                        self.submissionInProgress = false;
                        self.clicked = false;
                    },
                    self.formUpdateFailed
                );
            } else {
                localStorageSetItemWithFullWarning(self.file_id, JSON.stringify(self.form.data));
                self.submissionInProgress = false;
            }
        }

        this.removeSaveBtnClasses = function () {
            var buttons = [];
            buttons.push(document.getElementById('applicantValidateTheForm'));
            buttons.push(document.getElementById('btnValidateEnabled'));
            buttons.push(document.getElementById('paymentPageValidateTheForm'));
            buttons.push(document.getElementById('sendPageValidateTheForm'));
            buttons.forEach(function (btn) {
                if (!btn) return;
                btn.classList.remove('action-progress');
                var icon = btn.firstElementChild;
                if (!icon) return;
                icon.classList.remove('fal', 'fa-cog', 'fa-spin');
            });
        }

        this.addSaveBtnClasses = function () {
            var buttons = [];
            buttons.push(document.getElementById('applicantValidateTheForm'));
            buttons.push(document.getElementById('btnValidateEnabled'));
            buttons.push(document.getElementById('paymentPageValidateTheForm'));
            buttons.push(document.getElementById('sendPageValidateTheForm'));
            buttons.forEach(function (btn) {
                if (!btn) return;
                btn.classList.add('action-progress');
                var icon = btn.firstElementChild;
                if (!icon) return;
                icon.classList.add('fal', 'fa-cog', 'fa-spin');
            });
        }

        this.onSubmitForms = function ($event, form_types, current_page, next_page) {
            $event.preventDefault();
            $scope.error_messages = [];
            if (self.submissionInProgress) return;
            self.addSaveBtnClasses();


            self.submissionInProgress = true;

            var msg = $translate.instant("Form updated");

            /* validate forms */
            Helpers.checkFormErrors(form_types, $element, $scope, self.form).then(function (has_errors) {
                self.file_has_errors = has_errors;
                if (self.file_has_errors) {
                    self.error_messages_dict = $scope.error_messages_dict;
                    self.submissionInProgress = false;
                    self.removeSaveBtnClasses();
                    return;
                }
                self.clicked = true;

                var promises = [], stats;
                if (!self.is_draft) {
                    var all_stats = {'all': 0, 'missing': 0};
                    angular.forEach(form_types, function (form_type) {
                        var stats = Helpers.getFormPercantage(self.form[form_type], self.form.data);
                        all_stats.all += stats.all;
                        all_stats.missing += stats.missing;
                    });

                    if (all_stats.all && all_stats.missing) {
                        current_page.progress = parseInt((all_stats.all / all_stats.missing) * 100, 10);
                    } else {
                        current_page.progress = 100;
                    }

                    var selected_form_types = [];
                    angular.forEach(form_types, function (form_type) {
                        if (self.file_id) {
                            promises.push(Backend.updateFile(self.file_id, form_type, self.form.data));
                        } else {
                            selected_form_types.push(form_type);
                        }
                    });

                    if (selected_form_types.length) {
                        self.form.data[self.active_task.camundaName + "selection"] = "filevalidated";
                        promises.push(Backend.updateProcess(self.process_id, self.active_task.camundaName, selected_form_types.join(";"), self.form.data));
                    }


                    $q.all(promises).then(function () {
                            self.removeSaveBtnClasses();
                            var c_page = self.page;
                            next_page = self.nextPageFromDocuments(next_page);
                            self.page = next_page;

                            if (c_page !== next_page) {
                                $location.hash(next_page.name);
                                window.scrollTo(0, 0);
                            }
                            console.log("----- onSubmitForms not draft -----", msg, "----- /onSubmitForms -----")
                            //Notification.success({message: msg, replaceMessage: true});
                            self.submissionInProgress = false;
                            self.clicked = false;
                        },
                        function (err) {
                            self.removeSaveBtnClasses();
                            self.formUpdateFailed(err);
                        }
                    );
                } else {
                    self.removeSaveBtnClasses();
                    localStorageSetItemWithFullWarning(self.file_id, JSON.stringify(self.form.data));
                    self.submissionInProgress = false;
                    var c_page = self.page;
                    next_page = self.nextPageFromDocuments(next_page);
                    self.page = next_page;

                    if (c_page !== next_page) {
                        $location.hash(next_page.name);
                        window.scrollTo(0, 0);
                    }
                    console.log("----- onSubmitForms draft -----", msg, "----- /onSubmitForms -----")
                    self.submissionInProgress = false;
                    self.clicked = false;
                }
            }, function () {
                self.clicked = false;
                self.submissionInProgress = false;
                self.removeSaveBtnClasses();
            });
        };

        this.startProcessSuccessMessage = $translate.instant('File successfully submitted!');
        this.startProcessErrorMessage = $translate.instant('Failed to start file processing!');
        this.displayErrorPanelOnSendpage = function () {
            return self.file_has_errors && (self.error_messages_dict && Object.keys(self.error_messages_dict).length > 1 || !self.error_messages_dict.SENDPAGE);
        };
        this.onStartProcess = function ($event) {
            $event.preventDefault();
            if (self.submissionInProgress) return;
            self.submissionInProgress = true;
            self.addSaveBtnClasses();
            $http.get('/site-auth-check?service_id=' + self.service_id + '&lang=' + CURRENT_LANGUAGE).then(function (resp) {
                if (resp.data.status) {
                    if (!self.options.allow_anonymous && !self.isUserEmailVerified && !self.isUnverifiedEmailAllowed) {
                        self.submissionInProgress = false;
                        angular.element('#verify-email-to-send').modal('show');
                        self.removeSaveBtnClasses();
                        return;
                    }
                    /* validate forms */
                    var formsToCheck = [];
                    if (self.form.GUIDE && self.form.GUIDE.length) {
                        formsToCheck.push('GUIDE');
                    }
                    if (self.form.A5 && self.form.A5.length) {
                        formsToCheck.push('A5');
                    }
                    if (!self.form.data.isInitialPaymentDisabled) {
                        formsToCheck.push('PAYMENT');
                    }
                    if (self.form.data.showdocumenttab && self.form.A6 && self.form.A6.length) {
                        formsToCheck.push('A6');
                    }
                    if (self.form["SENDPAGE"] && self.form["SENDPAGE"].length) {
                        formsToCheck.push('SENDPAGE');
                    }
                    Helpers.checkFormErrors(formsToCheck, $element, $scope, self.form).then(function (has_errors) {
                        self.file_has_errors = has_errors;
                        if (self.file_has_errors) {
                            self.error_messages_dict = $scope.error_messages_dict;
                            self.submissionInProgress = false;
                            self.removeSaveBtnClasses();
                            return;
                        }
                        self.clicked = true;

                        self.pages[4].progress = 100;

                        /* process is already started */
                        if (self.process_id) {
                            // TODO: clean it up some time...
                            if (self.isApplicantPaymentActive()) {
                                self.form.data[self.active_task.camundaName + "selection"] = "filevalidated";
                                Backend.updateProcess(self.process_id, self.active_task.camundaName, ["PAYMENT"], self.form.data).then(function () {
                                    return Backend.updateForm(self.form.submit_url, self.form).then(function (response) {
                                        self.clearSubmissionProgress();
                                        window.location.href = self.url;
                                    });
                                });
                            } else {
                                Backend.updateForm(self.form.submit_url, self.form).then(function (response) {
                                    self.clearSubmissionProgress();
                                    window.location.href = self.url;
                                });
                            }
                        } else {
                            var promises = [];
                            if (self.file_id) {
                                // To make things quick just check last form on FE
                                // All forms need to be validated on BE before process starts!!!
                                formsToCheck.slice(-1).forEach((fToCheck) => {
                                    promises.push(Backend.updateFile(self.file_id, fToCheck, self.form.data));
                                });
                            }

                            Promise.all(
                                promises
                            ).then(() => {
                                return Backend.startFileProcess(self.file_id, self.form.data);
                            }).then(() => {
                                Notification.success({message: self.startProcessSuccessMessage, replaceMessage: true});
                                console.log("----- startprocess success -----", self.startProcessSuccessMessage, "----- /startprocess -----");
                                window.location.href = self.url;
                                self.clearSubmissionProgress();
                            }).catch(() => {
                                self.clearSubmissionProgress();
                                self.removeSaveBtnClasses();
                                console.log("----- startprocess error -----", self.startProcessErrorMessage, "----- /startprocess -----")
                                $scope.error_messages = [{label: self.startProcessErrorMessage}];
                                $scope.blockErrorsHeader = true;
                                Notification.error({
                                    templateUrl: "error_messages.html",
                                    scope: $scope,
                                    replaceMessage: true
                                });
                                self.submissionInProgress = false;
                                self.clicked = false;
                            });
                        }
                    });
                } else {
                    self.removeSaveBtnClasses();
                    self.submissionInProgress = false;
                    self.clicked = false;
                    angular.element('#authenticate-to-send').modal('show');
                }
            });
        };

        // External services actions... to be moved to separate locations
        this.onSubmitNitCertDisplay = function ($event) {
            $event.preventDefault();
            self.documentUrl = null;
            var nit;
            if (!self.form.data) return;
            Object.keys(self.form.data).some(function (fieldKey) {
                var value = self.form.data[fieldKey];
                if (!value || value.length !== 14) return; // nit has 14 digits
                nit = value;
                return true;
            });
            if (!nit) return;
            var nitAsArr = Array.from(nit);
            nit = [nitAsArr.slice(0, 4),
                nitAsArr.slice(4, 10),
                nitAsArr.slice(10, 13),
                nitAsArr.slice(-1)].map(function (sub) {
                return sub.join("");
            }).join("-"); // format nit from value
            self.documentUrl = $sce.trustAsResourceUrl('https://erp.conamype.gob.sv/tcpdf/docs/calificacionmypenva.php?nit=' + nit);
            Backend.log({
                key: "printMypeCertificateByNitService",
                value: "MYPE certificate display request for nit number: " + nit
            });
        }


        // Below old for Lesotho DB
        this.onSubmitLesothoLicenseSearchOld = function () {
            var mappingPromise = Promise.resolve();
            if (!this.licenseSearchMappings) {
                mappingPromise = $http({
                    url: location.origin + '/license-search-mappings',
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json"
                    })
                }).then(function (response) {
                    if (response && response.status < 400) {
                        self.licenseSearchMappings = response.data;
                    }
                });
            }
            this.lesothoLicenses = [];
            this.isLesothoLicenseSearchComplete = false;
            this.documentUrl = null;
            // Add new items to the array, to search other dbs
            mappingPromise.then(function () {
                var promises = self.licenseSearchMappings.map(function (mapping) {
                    return $http({
                        url: location.origin + '/search/' + mapping.gdb_db_name + '/' + mapping.gdb_db_version + '/' + encodeURIComponent(self.lesothoSearchInput),
                        method: "GET",
                        headers: new Headers({
                            "Content-Type": "application/json"
                        })
                    }).then(function (response) {
                        if (response && response.status < 400) {
                            Array.prototype.push.apply(self.lesothoLicenses, response.data.results.map(function (res) {
                                res.printoutId = res.registry_number;
                                res.dbId = mapping.gdbId;
                                res.mappingId = mapping._id;

                                return res;
                            }));
                        }
                    });
                });
                $q.all(promises).catch(function (e) {
                    self.lesothoLicenses = [];
                }).finally(function () {
                    self.isLesothoLicenseSearchComplete = true;
                });
            });
        }

        this.setLesothoLicenseIframeOld = function (license) {
            this.documentUrl = location.origin + '/form-preview-pdf/' + license.printoutId;
        }
        // End Below old for Lesotho DB

        this.bhutanSearchResults = [null];
        this.bhutanSearchInput = '';

        this.onSubmitBhutanSearch = function () {
            this.bhutanSearchResults = [null];
            this.documentUrl = null;
            $http({
                url: location.origin + '/search/' + self.bhutanSearchInput + '/bhutan-company',
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json"
                })
            }).then(function (response) {
                if (response && response.status < 400) {
                    self.bhutanSearchResults = response.data.results;
                    return;
                }
                self.bhutanSearchResults = [];
            }).catch(function (e) {
                self.bhutanSearchResults = [];
            });
        }

        this.paymentProvider = null;

        this.getIVeriCallbackRedirect = function (status) {
            return location.protocol + "//" + location.host + "/backend/iveri/" + status + "?redirect=" + encodeURIComponent(location.href);
        }

        this.isApplicantPaymentActive = function () {
            return self.active_task && self.active_task.assignee === 'applicantPayment';
        }

        this.isPayedDigitally = function () {
            return self.form && self.form.data && self.form.data.isPayedDigitally;
        }

        this.isPayedCash = function () {
            return self.form && self.form.data && self.form.data.isPayedCash;
        }

        this.isPaymentPartACompleted = function () {
            return self.form && self.form.data && self.form.data.isPaymentPartACompleted;
        }

        this.onSubmitPayment = function ($event) {
            $event.preventDefault();
            if (self.paymentInProgress || self.submissionInProgress) return;
            this.paymentProvider = self.form.data.paymentProviderSelected;
            self.paymentInProgress = true;
            self.submissionInProgress = true;
            var update = Promise.resolve();
            if (this.file_id) {
                if (!self.form.data.isInitialPaymentDisabled) {
                    update.then(Helpers.checkFormErrors(['PAYMENT'], $element, $scope, self.form, {ignoreIsPaymentPartACompleted: true}).then(function (hasErrors) {
                        if (hasErrors) {
                            throw new Error("Form has errors");
                        }
                        return Backend.updateFile(self.file_id, "PAYMENT", self.form.data, true)
                    }).catch(function (err) {
                    }));
                } else {
                    update.then(Backend.updateFile(self.file_id, "PAYMENT", self.form.data, true)).catch(function (err) {
                    });
                }
            }

            update.then(function () {
                var data = {};
                self.form.data.isPayedCash = false;
                self.form.data.isPayedDigitally = false;
                if (self.process_id) {
                    data['processId'] = self.process_id;
                }
                if (self.file_id) {
                    data['fileId'] = self.file_id;
                }
                if (self.paymentProvider === "frontdesk") {
                    self.form.data.isPayedCash = true;
                    self.submissionInProgress = false;
                    if (self.isApplicantPaymentActive()) {
                        self.onStartProcess(new Event("dummy"));
                    } else {
                        self.onSubmitForms($event, ['PAYMENT'], self.pages[3], self.pages[4]);
                    }
                    self.paymentInProgress = false;
                    return;
                }
                if (self.latestTransaction && self.latestTransaction.status === GLOBALS.TRANSACTION_STATUS.OK) {
                    self.form.data.isPayedDigitally = true;
                    self.submissionInProgress = false;
                    if (self.isApplicantPaymentActive()) {
                        self.onStartProcess(new Event("dummy"));
                    } else {
                        self.onSubmitForms($event, ['PAYMENT'], self.pages[3], self.pages[4]);
                    }
                    self.paymentInProgress = false;
                    return;
                }
                if (["npe", "cnr", "g2c", "leapa", "fedapay", "tresorpay"].includes(self.paymentProvider)) {
                    if (self.latestTransaction && self.latestTransaction.status === GLOBALS.TRANSACTION_STATUS.PENDING) {
                        self.paymentInProgress = false;
                        self.submissionInProgress = false;
                        $scope.error_messages = [{label: $translate.instant('You cannot start a new transaction for this file while the previous transaction was not resolved. Try again later, please.')}];
                        $scope.blockErrorsHeader = true;
                        Notification.error({templateUrl: "error_messages.html", scope: $scope, replaceMessage: true});
                        self.removeSaveBtnClasses();
                        return;
                    }
                    self.form.data.isPayedCash = false;
                    self.submissionInProgress = false;
                    var providerData = {
                        period: new Date().toISOString().slice(0, 10).replaceAll("-", "")
                    }

                    Object.keys(SERVICE_FORMS).some(function (formSetKey) {
                        if (!SERVICE_FORMS[formSetKey]) return;
                        return SERVICE_FORMS[formSetKey].some(function (form) {
                            if (["npe", "cnr"].includes(self.paymentProvider)) {
                                nit = Helpers.getFormioComponentByTag(form, "nitnpe");
                                const nit_2 = Helpers.getFormioComponentByTag(form, "nit");
                                const dui = Helpers.getFormioComponentByTag(form, "dui");
                                if (!nit) {
                                    nit = nit_2;
                                }
                                if (!nit) {
                                    nit = dui;
                                }
                                if (nit) {
                                    providerData.nit = self.form.data[nit.key];
                                    return true;
                                }
                            }
                            if (self.paymentProvider === "g2c") {
                                var agencyCode = Helpers.getFormioComponentByTag(form, "agencyCode");
                                var accountHeadId = Helpers.getFormioComponentByTag(form, "accountHeadId");
                                if (agencyCode && accountHeadId) {
                                    providerData.agencyCode = self.form.data[agencyCode.key];
                                    providerData.accountHeadId = self.form.data[accountHeadId.key];

                                    return true;
                                }
                            }
                            if (self.paymentProvider === "fedapay") {
                                var mobileNumber = Helpers.getFormioComponentByTag(form, "mobile-payment-phoneNo");
                                if (mobileNumber) {
                                    providerData.mobileNumber = self.form.data[mobileNumber.key];

                                    return true;
                                }
                            }
                            if (self.paymentProvider === "tresorpay") {
                                const paymentEnterprise = Helpers.getFormioComponentByTag(form, "payment-enterprise");
                                const paymentName = Helpers.getFormioComponentByTag(form, "payment-name");
                                const paymentSurname = Helpers.getFormioComponentByTag(form, "payment-surname");
                                const paymentRegion = Helpers.getFormioComponentByTag(form, "payment-region");
                                const paymentDepartment = Helpers.getFormioComponentByTag(form, "payment-department");
                                const paymentDistrict = Helpers.getFormioComponentByTag(form, "payment-district");
                                const paymentTown = Helpers.getFormioComponentByTag(form, "payment-town");
                                const paymentArea = Helpers.getFormioComponentByTag(form, "payment-area");
                                const paymentPostalCode = Helpers.getFormioComponentByTag(form, "payment-postalCode");
                                const paymentLocalisation = Helpers.getFormioComponentByTag(form, "payment-localisation");
                                const paymentPhone = Helpers.getFormioComponentByTag(form, "payment-phone");
                                if (mobileNumber) {
                                    providerData["payment-enterprise"] = self.form.data[paymentEnterprise.key];
                                    providerData["payment-name"] = self.form.data[paymentName.key];
                                    providerData["payment-surname"] = self.form.data[paymentSurname.key];
                                    providerData["payment-region"] = self.form.data[paymentRegion.key];
                                    providerData["payment-department"] = self.form.data[paymentDepartment.key];
                                    providerData["payment-district"] = self.form.data[paymentDistrict.key];
                                    providerData["payment-town"] = self.form.data[paymentTown.key];
                                    providerData["payment-area"] = self.form.data[paymentArea.key];
                                    providerData["payment-postalCode"] = self.form.data[paymentPostalCode.key];
                                    providerData["payment-localisation"] = self.form.data[paymentLocalisation.key];
                                    providerData["payment-phone"] = self.form.data[paymentPhone.key];

                                    return true;
                                }
                            }
                        });
                    });


                    //'20210505'
                    Backend.startPayment({
                        providerData: providerData,
                        data: data,
                        providerCode: self.paymentProvider
                    }).then(function (response) {
                        if (["cnr", "g2c"].includes(self.paymentProvider)) {
                            if (self.paymentProvider === 'g2c') {
                                window.open(response.transactionMetaData.redirect, '_blank');
                            } else {
                                window.location = response.transactionMetaData.redirect;
                            }
                            return;
                        }
                        window.location.reload()
                    }).catch(function () {
                        console.log("----- submitpayment error -----", "msg", "----- /submitpayment -----");
                        $scope.error_messages = [{label: $translate.instant('We could not process your transaction. Try again later, please.')}];
                        $scope.blockErrorsHeader = true;
                        Notification.error({templateUrl: "error_messages.html", scope: $scope, replaceMessage: true});
                        self.paymentInProgress = false;
                        self.submissionInProgress = false;
                        self.removeSaveBtnClasses();
                    });
                    return;
                }
                if (self.paymentProvider === "pagadito") {
                    if (self.latestTransaction && self.latestTransaction.status === GLOBALS.TRANSACTION_STATUS.PENDING) {
                        self.paymentInProgress = false;
                        self.submissionInProgress = false;
                        $scope.error_messages = [{label: $translate.instant('You cannot start a new transaction for this file while the previous transaction was not resolved. Try again later, please.')}];
                        $scope.blockErrorsHeader = true;
                        Notification.error({templateUrl: "error_messages.html", scope: $scope, replaceMessage: true});
                        self.removeSaveBtnClasses();
                        return;
                    }
                    self.form.data.isPayedCash = false;
                    self.submissionInProgress = false;
                    var nit = null;
                    Object.keys(SERVICE_FORMS).some(function (formSetKey) {
                        if (!SERVICE_FORMS[formSetKey]) return;
                        return SERVICE_FORMS[formSetKey].some(function (form) {
                            nit = Helpers.getFormioComponentByTag(form, 'nitnpe');
                            const nit_2 = Helpers.getFormioComponentByTag(form, 'nit');
                            const dui = Helpers.getFormioComponentByTag(form, 'dui');
                            if (!nit) {
                                nit = nit_2;
                            }
                            if (!nit) {
                                nit = dui;
                            }
                            if (nit) {
                                nit = self.form.data[nit.key];
                                return true;
                            }
                        });
                    });
                    Backend.startPayment({
                        providerData: {nit: nit},
                        data: data,
                        providerCode: 'pagadito'
                    }).then(function (response) {
                        //redirect
                        location = response.data.transactionMetaData.pagoes_redirect;
                    }).catch(function () {
                        console.log("----- submitpayment error -----", "msg", "----- /submitpayment -----");
                        $scope.error_messages = [{label: $translate.instant('We could not process your transaction. Try again later, please.')}];
                        $scope.blockErrorsHeader = true;
                        Notification.error({templateUrl: "error_messages.html", scope: $scope, replaceMessage: true});
                        self.paymentInProgress = false;
                        self.submissionInProgress = false;
                        self.removeSaveBtnClasses();
                    });
                    return;
                }
                if (self.paymentProvider === "iveri") {
                    var transactionData = {};
                    transactionData['Lite_Currency_AlphaCode'] = "ZAR";
                    transactionData['Lite_Merchant_ApplicationId'] = GLOBALS.IVERI_MERCHANT_APPLICATION_ID;
                    if (self.isApplicantPaymentActive()) {
                        var href = location.href.replace(/#page.*/, "#page5");
                        if (!href.match(/#page5$/)) {
                            href += '#page5';
                        }
                        transactionData['Lite_Website_Successful_Url'] = location.protocol + "//" + location.host + "/backend/iveri/1?redirect=" + encodeURIComponent(href);
                    } else {
                        transactionData['Lite_Website_Successful_Url'] = self.getIVeriCallbackRedirect(1)
                    }
                    transactionData['Lite_Website_Fail_Url'] = self.getIVeriCallbackRedirect(2);
                    transactionData['Lite_Website_TryLater_Url'] = self.getIVeriCallbackRedirect(4);
                    transactionData['Lite_Website_Error_Url'] = self.getIVeriCallbackRedirect(3);
                    transactionData['Lite_ConsumerOrderID_PreFix'] = "LITE";
                    transactionData['Ecom_BillTo_Online_Email'] = "some@email.com";
                    transactionData['Ecom_Payment_Card_Protocols'] = "IVERI";
                    transactionData['Ecom_ConsumerOrderId'] = null;
                    transactionData['Ecom_TransactionComplete'] = false;


                    Backend.startPayment({
                        providerData: transactionData,
                        data: data
                    }).then(function (response) {
                        var form = document.createElement("form");
                        form.setAttribute("method", 'post');
                        form.setAttribute("action", 'https://portal.nedsecure.co.za/Lite/Authorise.aspx');
                        transactionData = response.data.transactionData;
                        Object.keys(transactionData).forEach(function (key) {
                            var hiddenField = document.createElement("input");
                            hiddenField.setAttribute("type", "hidden");
                            hiddenField.setAttribute("name", key);
                            hiddenField.setAttribute("value", transactionData[key]);
                            form.appendChild(hiddenField);
                        })
                        document.body.appendChild(form);
                        self.paymentInProgress = false;
                        form.submit();
                    }).catch(function () {
                        console.log("----- submitpayment error -----", "msg", "----- /submitpayment -----")
                        Notification.error({
                            templateUrl: "error_messages.html",
                            message: 'We could not process your transaction. Try again later, please.',
                            replaceMessage: true
                        });
                        self.paymentInProgress = false;
                        self.submissionInProgress = false;
                    });
                }
            });
        }
        // END External services

        this.sendVerificationEmail = function () {
            $http({
                url: window.origin + '/verify-email',
                method: "POST",
                body: JSON.stringify({lang: CURRENT_LANGUAGE}),
                headers: new Headers({
                    'Authorization': self.token,
                    "Content-Type": "application/json"
                }),
                transformResponse: null
            }).then(function (response) {
                if (response.status !== 200) {
                    throw(new Error("Bad response"));
                }
                console.log("----- verifemail success -----", response.data, "----- /verifemail -----")
                Notification.success({
                    message: $translate.instant("Email verification link sent."),
                    replaceMessage: true
                });
            }).catch(function (err) {
                console.log("----- verifemail error -----", response.data, "----- /verifemail -----")
                Notification.error({
                    templateUrl: "error_messages.html",
                    message: 'Failed to send verification link. Try again later, please.',
                    replaceMessage: true
                });
            });
        }

        this.clearSubmissionProgress = function () {
            window.setTimeout(function () {
                self.submissionInProgress = false;
                self.paymentInProgress = false;
            }, 10000);
        }

        this.getTargetUrl = function (_url) {
            if (_url == undefined) {
                return null;
            }
            return _url + '#' + self.file_id;
        };

        this.getRequiredForms = function (form_type) {
            return Helpers.getRequiredForms(self.form[form_type]);
        };

        this.getFormTitle = function (form) {
            return Helpers.getFormTitle(form);
        };

        this.debug = function () {
            console.log(self);
        };

        this.setDocumentName = function (doc) {
            var key = Helpers.getFileKey(self.form.data, doc);
            if (key != null) {
                var title = "";
                Object.keys(self.form).some(function (formKey) {
                    if (!self.form[formKey]) return;
                    if (!self.form[formKey][0]) return;
                    if (!self.form[formKey][0].components) return;
                    title = DirectFormioUtils.getComponent(self.form[formKey][0].components, key, true);
                    if (title) {
                        return true;
                    }
                });

                title = (title && title.label) || key;

                Backend.setDocumentName(doc, title)
            }
        };

        this.printAnyForm = function (ev) {
            Helpers.printAnyForm({data: self.form.data, formId: ev.detail, service_id: self.service_id});
        };

        this.updateDataFromJWT = function () {
            var casTaggedComponents = [];
            if (!self.token) return;
            var decoded = jwtDecode(self.token);
            if (!decoded) return;
            if (!self.form.data) return;
            Object.keys(SERVICE_FORMS).forEach(function (formKey) {
                if (!SERVICE_FORMS[formKey] || !SERVICE_FORMS[formKey][0]) return;
                casTaggedComponents = casTaggedComponents.concat(Helpers.getCasTaggedFormioComponents(SERVICE_FORMS[formKey][0]));
            });
            casTaggedComponents.forEach(function (component) {
                component.tags.forEach(function (tag) {
                    if (!tag.startsWith("cas_")) return;
                    if (self.form.data[component.key] != null) return;
                    var key = tag.slice(4);
                    if (!key) return;
                    var value = null;
                    if (['fname', 'lname'].includes(key)) {
                        value = decoded[key];
                    } else {
                        if (!decoded.properties) return;
                        decoded.properties.some(function (casProp) {
                            if (casProp.key === key) {
                                value = casProp.value;
                                return true;
                            }
                        });
                    }

                    if (value != null) {
                        self.form.data[component.key] = value;
                    }
                });

            });
        }

        this.onRegistrationRedirect = function (ev) {
            self.submissionInProgress = true;
            self.clicked = true;
            var redirect = this.getLoginWithRedirect();
            var nextPage = self.getNextVisiblePage(self.page);
            var hash = encodeURIComponent("#" + nextPage.name);
            // %23 - uri encoded #
            if (redirect.match(/%23.+$/)) {
                redirect = redirect.replace(/%23.+$/, hash);
            } else {
                redirect += hash;
            }
            var components = [];
            Object.keys(SERVICE_FORMS).forEach(function (formKey) {
                if (!SERVICE_FORMS[formKey] || !SERVICE_FORMS[formKey][0]) return;
                components = components.concat(Helpers.getPrefillTaggedFormioComponents(SERVICE_FORMS[formKey][0]));
            });
            if (components && components.length) {
                var params = {};
                components.forEach(function (component) {
                    component.tags.forEach(function (tag) {
                        if (!tag.startsWith("prefill_register_")) return;
                        if (!self.form.data[component.key]) return;
                        var paramName = tag.slice("prefill_register_".length);
                        if (!paramName) return;
                        if (!self.form.data[component.key]) return;
                        params["prefill_register_" + paramName] = self.form.data[component.key].trim();
                    });
                });
                if (Object.keys(params).length) {
                    redirect += '&' + Backend.objectToUrlParams(params);
                }
            }
            var promises = [];
            if (self.page.formIds.length) {
                promises.push(Helpers.checkFormErrors(self.page.formIds, $element, $scope, self.form).then(function (has_errors) {
                    self.file_has_errors = has_errors;
                    if (self.file_has_errors) {
                        self.error_messages_dict = $scope.error_messages_dict;
                        throw new Error("Failed form validation");
                    } else {
                        return Promise.all(self.page.formIds.map(function (form_type) {
                            return Backend.updateFile(self.file_id, form_type, self.form.data, true);
                        }));
                    }
                }));
            }
            if (!promises.length) {
                window.location.href = redirect;
            } else {
                Promise.all(promises).then(function () {
                    self.submissionInProgress = false;
                    self.clicked = false;
                    window.location.href = redirect;
                }).catch(function (err) {
                    self.submissionInProgress = false;
                    self.clicked = false;
                    self.removeSaveBtnClasses();
                });
            }
        }

        // in Select component - uiSelect
        $scope.$on('selectLoaded', function (ev) {
            var component = ev.targetScope;
            var componentSchema = component.component;
            if (!component || !componentSchema || !component.selectItems) return;
            component.selectItems.forEach(function (item) {
                if (!self.formioSelectItemsMapping[componentSchema.key]) {
                    self.formioSelectItemsMapping[componentSchema.key] = {};
                }
                self.formioSelectItemsMapping[componentSchema.key][item.id] = item.value;
            });

            //I think that below is used only for regular non catlog selects
            if (!component || !component.data || !component.data.values || !Array.isArray(component.data.values)) {
                return;
            }
            component.data.values.forEach(function (value) {
                if (!value.label) return;
                value.label = $translate.instant(value.label);
            });
        })

        $scope.$on('confirm', function (event, data) {
            if (self.page) {
                var next_page = self.getNextVisiblePage(self.page);
                self.onSubmitForms(event, self.page.formIds, self.page, next_page);
            } else {
                var validate_forms = ['GUIDE'];
                self.onSubmitForms(event, validate_forms, self.pages[0], self.pages[1]);
            }
        });

        $scope.$on('fileUploaded', function (event, fileName, fileInfo) {
            self.form['can_complete'] = false;
            fileInfo.document_id = fileInfo.name;
            self.setDocumentName(fileInfo);

            var promises = [];
            if (!self.is_draft) {
                if (self.process_id) {
                    promises.push(Backend.updateForm(self.form.submit_url, self.form).then(function (response) {
                    }));
                } else {
                    promises.push(Backend.updateFile(self.file.file_id, 'A6', self.form.data, true));
                }

                $q.all(promises).then(function () {
                    self.form['can_complete'] = true;
                });
            } else {
                localStorageSetItemWithFullWarning(self.file_id, JSON.stringify(self.form.data));
            }
        });

        $scope.$on('formSubmit', function (err, submission) {
            $scope.error_messages = [];
            var variables = {};
            variables.json = {'value': JSON.stringify(submission.data), 'type': 'json'};
        });

        $scope.$watch('app_service.form.data', _.debounce(function (newValue, oldValue) {
            $scope.$apply(() => {
                Object.keys(self.form.data).forEach(function (dataKey) {
                    if (self.form.data[dataKey] === '') {
                        Object.keys(SERVICE_FORMS).some(function (formKey) {
                            if (SERVICE_FORMS[formKey] && SERVICE_FORMS[formKey].length) {
                                return SERVICE_FORMS[formKey].some(function (form) {
                                    var comp = DirectFormioUtils.getComponent(form.components, dataKey);
                                    // calculateValue needs to be excluded, otherwise it might produce an infinite loop
                                    // (for the case when calculate value resolves to empty string)
                                    if (comp && comp.type !== 'textfield' && !comp.calculateValue
                                        && self.form.data[dataKey] !== null) {
                                        // if not done formio validation throws type exception for some types
                                        self.form.data[dataKey] = null;
                                    }
                                });
                            }
                        });
                    }
                });
                Object.keys(self.form).forEach(function (formKey) {
                    if (formKey === 'data') return; // form data, not form
                    angular.forEach(self.form[formKey], function (f) {
                        Helpers.propagateResetHidden(f.components, self.form.data);
                    });
                });

                if (self.is_draft) {
                    localStorageSetItemWithFullWarning(self.file_id, JSON.stringify(self.form.data));
                }
                if (SERVICE_FORMS.useSchemaFilter && SERVICE_FORMS.useSchemaFilter.length && SERVICE_FORMS.GUIDE
                    && SERVICE_FORMS.GUIDE[0] && self.page.name === "page1") {
                    self.loadDefaultForms(self.service_id, self.is_draft);
                }
                // It looks like right now isPayedCash is redundant, but it is left for legacy purpose
                const newIsPayedCash = (self.form.data.paymentProviderSelected === "frontdesk");
                if (self.form.data.isPayedCash !== newIsPayedCash) {
                    self.form.data.isPayedCash = newIsPayedCash;
                }
                self.calculateProgressForPages(true);
            })
            self.updateDataFromJWT();
        }, 1000), true);

        $scope.$watch('app_service.page', function (nu, old) {
            if (nu.name === old.name) return;
            $scope.error_messages = [];
        }, true);

        $rootScope.$on('maybeDoneLoading', function () {
            self.setInitialPage();
        })
    }]);