app.controller('DocumentListController', ['$scope', '$http', '$window', 'Backend', 'Helpers', '$q', 'cfg',
    function($scope, $http, $window, Backend, Helpers, $q, cfg) {
        this.original_file_list = {'applications':[], 'documents': [], 'certificates': []};
        this.file_list = {'applications':[], 'documents': [], 'certified_documents': [], 'other_documents': [],
                            'certificates': []};
        this.order_by = 'created_at';
        var self = this;
        self.documents;
        self.documents_count = 0;
        this.current_applications_page = 1;
  
        this.initService = function () {
            var promises = [];

            promises.push(Backend.getUserDocuments().then(function (data) {
                angular.forEach(data, function (doc) {
                    doc.file_url = Backend.backend_url+'files/'+doc.file_id+'/document/'+doc.document_id;
                });
                self.original_file_list['documents'] = self.sortDocuments(data);
                self.file_list['documents'] = data;
                self.documents_count += self.file_list['documents'].length;
            }));
            promises.push(Backend.getUserCertificates().then(function (data) {
                angular.forEach(data, function (cert) {
                    cert.file_url = Backend.backend_url+'certificates/'+cert.id;
                });
                self.original_file_list['certificates'] = self.sortDocuments(data);
                self.file_list['certificates'] = data;
                self.documents_count += self.file_list['certificates'].length;
            }));

            $q.all(promises).then(function () {
                angular.forEach(['documents', 'certificates', 'alerts', 'messages', 'payments'], function (list_type) {
                    console.log('list type', list_type);
                    self.file_list[list_type] = angular.copy(self.original_file_list[list_type]);
                });
    
                /* this is to make sure that documents are close to each other */
                var temp_dict = {};
                angular.forEach(self.file_list['documents'], function (doc) {
                    if (!temp_dict[doc.file_id]) { temp_dict[doc.file_id] = []; }
                    temp_dict[doc.file_id].push(doc);
                });
                angular.forEach(temp_dict, function (doc_list) {
                    angular.forEach(doc_list, function (doc) {
                        if (doc.is_valid) {
                            self.file_list['certified_documents'].push(doc);
                        }
                        else {
                            self.file_list['other_documents'].push(doc);
                        }
                    });
                });
            });
            
        };

        this.processApplications = function(data) {
            angular.forEach(data.results, function (file) {
                Helpers.setFileApplicantListingProcessParams(file);
            });
            self.total_applications = data.count;
            self.file_list['applications'] = data.results;
        }

        this.sortDocuments = function (list) {
            if (!list) return list;
            return list.sort(function (a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }

        this.documentDownload = Helpers.documentDownload;

        this.certDownload = function (document, process_id) {
            $http.get(document.file_url, {responseType: 'arraybuffer'}).then(Helpers.placeFilePromiseCallback);
        };

        this.getServiceName = function(obj) {
            var file = self.getFileByObject(obj);
            var service_name = "";
            if (file) {
                if (file.file_id) { service_name = file.service_name; }
                else { service_name = file.name; }
            }
            return service_name;
        };

        this.getFileByObject = function(obj) {
            var file = null;
            angular.forEach(self.file_list['applications'], function (appl) {
                if (appl.file_id) {
                    if (obj.file_id && obj.file_id == appl.file_id) { file = appl; }
                } else {
                    if(obj.process_id && obj.process_id == appl.id){ file = appl; }
                }
            });
            return file;
        };
}]);
