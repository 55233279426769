import * as _ from "lodash";

app.controller('ApplicationServiceBusinessEntityController', ['$scope', '$http', '$window', 'Backend', 'cfg', '$translate', '$compile',
function($scope, $http, $window, Backend, cfg, $translate, $compile) {
    var self = this;
    self.token = cfg.token;

    this.businessEntity = { isAddVisible: false, list: [], selected: {}, error: false };
    this.newBusinessEntity = { business_entity_id: null, name: "", isNew: true };

    this.setupCompanyNameFromBusinessEntity = function () {
        if (SERVICE_FORMS['A5'] && SERVICE_FORMS['A5'][0] && self.form.data) {
            var companyNameComp = Helpers.getFormioComponentByTag(SERVICE_FORMS['A5'][0], 'company-name');
            if (companyNameComp && self.businessEntity.selected.name) {
                if (self.isCompanyNameSetupFromEntity || !self.form.data[companyNameComp.key]) {
                    self.form.data[companyNameComp.key] = self.businessEntity.selected.name;
                    self.isCompanyNameSetupFromEntity = true;
                }
            }
        }
    }

    this.getBusinessEntities = function () {
        return Backend.getBusinessEntities(null, null, true).then(function (data) {
            if (data && data.count) {
                self.businessEntity.list = data.results.filter(function (be) {
                    return be.has_create_access;
                });
            }
            return self.businessEntity.list;
        }).catch(function () {});
    }

    this.toggleBusinessEntitySelection = function (show) {
        var modal = angular.element('#select-business-entity').modal();
        if (show) {
            modal.show();
            angular.element('#overlay').show();
        } else {
            modal.hide();
            angular.element('#overlay').hide();
            angular.element('body').removeClass("modal-open");
            self.setupCompanyNameFromBusinessEntity();
        }
    }

    this.changeEntity = function (be) {
        console.log(be);
        self.businessEntity.selected = be;
        console.log(self.businessEntity);
    }

    this.acceptEntity = function (be) {
        Backend.patchFile(self.file_id, { business_entity_id: self.businessEntity.selected.business_entity_id }).then(function(cd) {
            self.businessEntity.error = false;
            self.toggleBusinessEntitySelection();
        }, function (err) {
            self.businessEntity.error = true;
        });
    }

    this.getPreselectedBE = function () {
        return location.search.replace(/.+preselectedBE=(.+?)(&.+|$)/, '$1');
    }

    this.initBusinessEntitySelection = function () {
        self.getBusinessEntities().then(function () {
            if (!self.businessEntity.list || self.businessEntity.list.length === 0) return;
            if (self.file_info && self.file_info.business_entity_id) return;
            if (!self.businessEntity.selected.business_entity_id) {
                var preslectedBE = self.getPreselectedBE();
                if (preslectedBE) {
                    self.businessEntity.list.some(function (entity) {
                        if (entity.business_entity_id === preslectedBE) {
                            self.businessEntity.selected = entity;
                            return true;
                        }
                    });
                }
                self.toggleBusinessEntitySelection(true);
            }
        });

        jQuery(document).ready(function () {
            self.businessEntity.add = function () {
                if (_.trim(self.businessEntity.selected.name)) {
                    Backend.addBusinessEntity({ name: self.businessEntity.selected.name }).then(function(cb) {


                        self.businessEntity.selected = cb.data;
                        console.log("BE added =>", self.businessEntity.selected);
                        Backend.patchFile(self.file_id, { business_entity_id: self.businessEntity.selected.business_entity_id }).then(function(cb) {
                            self.businessEntity.error = false;
                            self.toggleBusinessEntitySelection();
                        }, function (err) {
                            self.businessEntity.error = true;
                            console.log("ERRRR")
                        });
                    });
                }
            }
        });
    }

    this.onApplicationFileDataLoad = function(event) {
        self.file = event.detail.file;
        self.file_id = event.detail.file_id;
        self.file_info =event.detail.file_info;
        if (GLOBALS.USE_BUSINESS_ENTITIES && !event.detail.service.is_external) {
            if (self.token) {
                self.initBusinessEntitySelection();
            }
        }
    };

    $scope.$watch('be_controller.businessEntity.selected', (newVal) => {
        var elem = document.getElementById('menu-business-entity-name');
        if (elem) {
            if (newVal && newVal.name) {
                elem.innerText = newVal.name;
            } else {
                elem.innerText = '';
            }
        }
    });

    addEventListener('applicationFileLoaded', this.onApplicationFileDataLoad);
    addEventListener('applicationFileData', this.onApplicationFileDataLoad);

    if (GLOBALS.USE_BUSINESS_ENTITIES && !self.fileId) {
        dispatchEvent(new CustomEvent("requestFileData", {}));
    }


}]);