app.controller('ManageBusinessEntityController', ['$scope', '$http', '$window', 'Backend', 'cfg', '$translate', '$compile',
    function($scope, $http, $window, Backend, cfg, $translate, $compile) {
        var self = this;
        self.businessEntity = {};
        self.emailToAdd = "";
        self.nameNew = "";
        self.nameEdition = false;
        self.nbRoots = 0;

        GLOBALS.getConfirmationModalBody = function (result) {
            return $compile('<p class="confirmation-modal-text">This modification is considered <span class="strong">critical</span>. Please confirm the following change:</p><div class="confirmation-modal-change-detail-container"><div class="orig-name"><h6 class="upper-text">Original name</h6><span class="strong">{{ manage_business_entities_ctrl.businessEntity.name }}</span></div><div><h6 class="upper-text">New name</h6><span class="strong">{{ manage_business_entities_ctrl.nameNew }}</span></div></div>')($scope);
        }

        GLOBALS.confirmModal = function (result) {
            if (result) {
                self.update();
                return;
            }
            self.cancelNameEdition();
        }
      
        this.init = function(entity_id) {
            self.businessEntity.business_entity_id = entity_id;
            Backend.getBusinessEntity(entity_id).then(function (data) {
                if (!data) return;
                self.orderBesByUsers(data);
                self.businessEntity = data;
                self.countRoots();
                self.nameNew = data.name;
            }).catch(function(data) {
                self.accessDenied = true;
            })
        };

        this.countRoots = function() {
            self.nbRoots = 0;
            self.businessEntity.users.forEach(function(user) {
                if (user.has_root) self.nbRoots++;
            })
        }

        this.canAdd = function () {
            if(!self.emailToAdd) return false;
            return self.businessEntity.users.every(function (user) {
                return user.email !== self.emailToAdd;
            });
        }

        this.addUser = function () {
            if (!self.canAdd()) return;
            var nuUser = {
                "email": this.emailToAdd, "has_read": true, "has_write":
                    false, "has_create": false, "has_root": false
            };

            self.businessEntity.users.push(nuUser);
            self._updateBEs().then(function () {
                self.emailToAdd = '';
                self.countRoots();
            }, function () {
                self._removeUser(nuUser);
            })
        };

        this.cancelNameEdition = function() {
            self.nameNew = self.businessEntity.name;
            self.nameEdition = false;
            if(!$scope.$$phase) { // this hack is needed cause we mix regular DOM with angular here
                $scope.$digest();
            }
        }

        this.update = function() {
            self.businessEntity.name = self.nameNew;
            self.businessEntity.users.forEach(function(user){
                if (!user.has_write) user.has_read = false;
            })
            self._updateBEs().then(function(result) {
                self.nameEdition = false;
                self.countRoots();
            }, function(error) {
                console.error(error)
            });
        };

        this._updateBEs = function () {
            return Backend.updateBusinessentity(self.businessEntity).then(function(result) {
                self.orderBesByUsers(result.data);
                self.businessEntity = result.data;
                self.countRoots();
            });
        }

        this.orderBesByUsers = function (data) {
            if (data && data.users) {
                data.users.sort(function (a, b) {
                    return ('' + a.email).localeCompare(b.email);
                });
            }

            return data;
        }

        this._removeUser = function (user) {
            var index = self.businessEntity.users.indexOf(user);
            if (index !== -1) self.businessEntity.users.splice(index, 1);
            self._updateBEs().then(function() {
                self.countRoots();
            });
        }

        this.removeUser = function(user) {
            var text = $translate.instant('Are you sure you want to remove');
            if(confirm(text+ ' '+user.email)) {
                self._removeUser(user);
            }
        };
}]);
