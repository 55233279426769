app.controller('ServicesController', ['$scope', '$http', '$window', 'Backend', 'cfg',
    function($scope, $http, $window, Backend, cfg) {
        var self = this;
        self.services = [];

        this.initService = function() {
            self.services = cfg.services;
            console.log(self.services);
        }

        this.openNewService = function(url) {
            if(url) {
                window.location.href = url;
            }
        }
}]);
