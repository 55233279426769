app.controller('FileProcessListController', ['$scope', '$http', '$window', 'Backend', 'Helpers', 'cfg',
    function($scope, $http, $window, Backend, Helpers, cfg) {
        var self = this;
        self.order_by = 'created_at';
        self.reverse = true;
        self.applications;
        self.total_applications = 0;
        self.current_applications_ordering = '-created_at';
        this.current_applications_page = 1;

        this.initService = function () {
            /*Backend.getUserFiles().then(function (data) {
                self.applications = data;
            });*/
            Backend.getUserFiles(1, self.current_applications_ordering).then(self.processApplications);
        };

        this.processApplications = function(data) {
            angular.forEach(data.results, function (file) {
                Helpers.setFileApplicantListingProcessParams(file);
            });
            self.total_applications = data.count;
            self.applications = data.results;
            console.log(self.applications);
        };

        this.showFileProcessing = function(file) {
            console.log(file);
            if (file.file_state_code == 'draft') {
                window.location.href = '/redirect-to-file/'+file.service_id+'/'+file.file_id;
            }
            else if (file.file_state_code === 'to_correct' || file.file_state_code === 'applicantPayment') {
                var processId = file.id;
                if (!processId) {
                    processId = file.process_instance_id;
                }
                var serviceId = file.serviceId;
                if (!serviceId) {
                    serviceId = file.service_id;
                }
                window.location.href = '/redirect-to-process/'+serviceId+'/'+processId;
            } else {
                /* set url params */
                var process_id = file.id;
                if (!process_id) {
                    process_id = file.process_instance_id;
                }
                window.location.href = '/redirect-to-name/application_details/?process_id='+process_id;
                //$location.search({file_id: file.file_id});
            }
        };

        this.onApplicationsPageChange = function(new_page) {
            self.current_applications_page = new_page;
            Backend.getUserFiles(new_page, self.current_applications_ordering).then(self.processApplications);
        };

        this.orderFilesBy = function(list_type, value, data_type) {
            var opts = Object(arguments[3]);
            self.reverse = (value !== null && self.order_by === value) ? !self.reverse : false;
            self.order_by = value;
            if(list_type!='applications') {
                self.file_list[list_type] = self.original_file_list[list_type].sort(Helpers.sorters(value, data_type, opts));
            } else {
                if(self.reverse) {
                    self.current_applications_ordering = "-"+value;
                } else {
                    self.current_applications_ordering = value;
                }
                Backend.getUserFiles(self.current_applications_page, self.current_applications_ordering).then(self.processApplications);
            }
        };
    
        this.deleteFile = function(file) {
            file.deleting = true;
            Backend.deleteFile(file.file_id).then(function() {
                self.onApplicationsPageChange(self.current_applications_page);
            })
        };
}]);
