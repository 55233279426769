
/**
 * Controller for displaying File lists for different roles, including 'applicant'
 */
app.controller('SingleApplicationController', ['$scope', '$rootScope', 'Backend', 'SiteConfiguration', '$location', 'Helpers', '$translate', '$translatePartialLoader',
    function($scope, $rootScope, Backend, SiteConfiguration, $location, Helpers, $translate, $translatePartialLoader) {
    this.file = null;
    this.file_history = [];
    this.form = {'data': {}};
    this.document_list = {};
    this.certificate_list = {};
    this.form_data = {};
    this.history_loading_message = null;
    this.document_loading_message = null;
    this.certificate_loading_message = null;
    this.GLOBALS = Helpers.GLOBALS;
	var self = this;

    this.initService = function(process_id) {
        addEventListener("PRINTOUT", this.printAnyForm.bind(this));
        Backend.getProcess(process_id).then(function(file) {
            self.file = file;
            // sinnce the pligin cannot access menu_items we do it the hard way
            var rolesMenu = document.getElementById('yw3');
            if (rolesMenu) {
                Array.from(rolesMenu.children).forEach(function (menuItem) {
                    menuItem.className = menuItem.className.replace('active', '');
                });
                var menuItem = document.createElement('li');
                menuItem.className = 'active';
                var link = menuItem.appendChild(document.createElement('a'));
                link.className = 'btn-primary-dark';
                var span = link.appendChild(document.createElement('span'));
                span.className = 'd-inline-block pr-0';
                span.innerHTML = $translate.instant(self.file.name);
                rolesMenu.appendChild(menuItem);
            }
            
            /* add service translations */
            if(self.file.serviceId) {
                $translatePartialLoader.addPart('general');
                $translatePartialLoader.addPart(self.file.serviceId);
            }
            Backend.getRoleProcessVariables(self.file, undefined, true).then(function(file_data) {
                self.form.data = file_data.variables;
                self.getCertificates(file, self.form.data);
                self.getDocuments(file, self.form.data);
            });
            self.file_history = Helpers.getFileHistory(file, { isApplicant: true });
            self.getApplicantForm(self.file);
        });
	};
	
    this.getApplicantForm = function (file) {
        var process_id = file.id;
        if (!process_id) {
            process_id = file.process_instance_id;
        }
        Backend.getProcessCamundaPreviewForms(process_id).then(function (form_list) {
            angular.forEach(form_list.data, function (form) {
                var opts = {};
                if (form.formId.endsWith('reviewwithtabs')) {
                    opts.process_id = file.id;
                }
                Backend.getFormioForm(form.formId, opts).then(function (result) {
                    self[form.formType] = result.data;
                    if (form.formId.endsWith('reviewwithtabs') && result.data && result.data[0]) {
                        self.form.data = Object.assign(self.form.data, result.data[0].mycardEditGridData);
                    }
                });
            });
        });
    };

    this.getHistory = function(file) {
        self.history_loading_message = "Loading history";
        self.file_history = Helpers.getFileHistory(file, { isApplicant: true });
        self.history_loading_message = "No file history";
    };

    this.getDocuments = function(file, data) {
        self.document_loading_message = "Loading documents";
        Backend.getProcessDocuments(file.id).then(function(result) {
            self.document_list = Helpers.prepareDocuments(result, file, data, 'document');
            self.document_loading_message = "No file documents";
        });
    };

    this.getCertificates = function(file, data) {
        self.certificate_loading_message = "Loading certificates";
        Backend.getUserProcessCertificates(file.id).then(function(result) {
            self.certificate_list = Helpers.prepareDocuments(result, file, data, 'certificate');
            self.certificate_loading_message = "No file certificates";
        });
    };

    this.documentDownload = Helpers.documentDownload;

    this.certDownload = function (file_name, cert) {
        Helpers.documentDownload(cert);
    };

    this.documentCount = function () {
        return Helpers.fileListCount(self.document_list);
    };

    this.certificateCount = function () {
        return Helpers.fileListCount(self.certificate_list);
    };

    this.printAnyForm = function (ev) {
        Helpers.printAnyForm({ data: self.form.data, formId: ev.detail, service_id: self.service_id });
    };

    this.getUrlParams = function () {
        var params = $location.search();
        if (!$.isEmptyObject(params)) {
            return params;
        }

        var query = location.search.substr(1);
        var result = {};
        query.split("&").forEach(function (part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }

    var params = this.getUrlParams();
    console.log(params);
    if (params['process_id'] != null && params['process_id'].length) {
        if ((self.file != null && self.file.id != params['process_id']) || self.file == null) {
            self.initService(params['process_id']);
        }
    }
}]);